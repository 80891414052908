// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-catering-repairs-js": () => import("./../../../src/pages/catering-repairs.js" /* webpackChunkName: "component---src-pages-catering-repairs-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-ferric-meter-js": () => import("./../../../src/pages/ferric-meter.js" /* webpackChunkName: "component---src-pages-ferric-meter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-repairs-js": () => import("./../../../src/pages/industrial-repairs.js" /* webpackChunkName: "component---src-pages-industrial-repairs-js" */),
  "component---src-pages-other-service-js": () => import("./../../../src/pages/other-service.js" /* webpackChunkName: "component---src-pages-other-service-js" */),
  "component---src-pages-pcb-repairs-js": () => import("./../../../src/pages/pcb-repairs.js" /* webpackChunkName: "component---src-pages-pcb-repairs-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

